//学生基本信息
<template>
  <div class="stu_info" v-if="actUserInfo">
    <!-- 姓名,年纪,ID,手机号，状态,总课时,剩余课时 -->
    <div class="flex_h ub-wrap">
      <div class="margin_right_20">姓名：{{ actUserInfo.student_name }}</div>
      <div class="margin_right_20">年龄：{{ actUserInfo.nian }}岁{{ actUserInfo.yue }}月</div>
      <div class="margin_right_20">ID：{{ actUserInfo.user_id }}</div>
      <div class="margin_right_20">手机号：{{ actUserInfo.tel }}</div>
      <div class="">预报名课程：{{ actUserInfo.student_sub_name ? actUserInfo.student_sub_name : "无" }}</div>
    </div>

    <!--当前状态，总课时，剩余课时  -->
    <div class="flex_h margin_top_20">
      <div class="margin_right_20">当前状态：{{ stuState(actUserInfo.join_state) }}</div>
      <div class="margin_right_20">总课次：{{ actUserInfo.total_hour }}</div>
      <div class="">剩余课次：{{ actUserInfo.balance_hour }}</div>
    </div>

    <div class="flex_h margin_top_20">
      <div class="">
        历史记录：
        <time-line :list="activities" v-if="activities.length > 0" :type="1"></time-line>
        <span v-else>暂无记录</span>
      </div>
    </div>

    <!-- 表格部分 -->
    <div class="dv_table_view_content margin_top_20">
      <el-table style="width: 99%; margin: auto" border stripe :data="actUserInfo.student_subjects" :max-height="maxHeight">
        <!-- 所在班级 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="class_name" label="所在班级" min-width="25%"></el-table-column>
        <!-- 班级ID -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="class_id" label="班级ID" min-width="25%"></el-table-column>
        <!-- 班级老师 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="teacher_name" label="班级老师" min-width="25%"></el-table-column>
        <!-- 班级开设课程 -->
        <el-table-column :show-overflow-tooltip="true" align="center" prop="class_sub_name" label="班级开设课程" min-width="25%"></el-table-column>
        <!-- 课程剩余课时/总课时 -->
        <!-- <el-table-column :show-overflow-tooltip="true" align="center" label="课程剩余课时/总课时" width="200">
                    <template slot-scope="scope">
                        <div>{{scope.row.remainLesson}}/{{scope.row.totalLesson}}</div>
                    </template>
                </el-table-column> -->
        <!-- 状态 -->
        <!-- <el-table-column :show-overflow-tooltip="true" align="center" prop="state" label="状态" width="240"></el-table-column> -->
      </el-table>

      <!-- 顶部的分页器部分 -->
      <!-- <div class="bom_page margin_bom_20" v-if="tableData&&tableData.length>0">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currPage" :page-sizes="pageSizeArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalSize">
                </el-pagination>
            </div> -->
    </div>
  </div>
</template>
<script>
import TimeLine from "./components/TimeLine.vue";
export default {
  data() {
    return {
      //表格最大高度
      maxHeight: 0,
      tableData: [
        {
          className: "程序蛙少儿编程一班",
          classId: "56230424",
          teacherName: "陈老师",
          lessonName: "机械蛙、创意大颗粒、百变机械、Python编程",
          //剩余课时
          remainLesson: 10,
          //总课时
          totalLesson: 40,
          //状态
          state: "结业",
        },
      ],

      //表格的宽度
      tableWidth: 0,

      //总的数据条数
      totalSize: 30,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      // 用户信息
      actUserInfo: null,

      reverse: false,
      activities: [],
    };
  },
  created() {
    this.init();
    let actUserInfo = this.$route.query.userInfo;
    if (actUserInfo) {
      this.actUserInfo = JSON.parse(actUserInfo);
      console.log(this.actUserInfo);
    }
    this.userInfo = this.getUserInfo();
    this.getTimeline();
  },
  methods: {
    init() {
      this.maxHeight = document.body.clientHeight - 280;
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      // this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      // console.log("当前页码是:", this.currPage);
      //重新刷新数据
      // this.httpData();
    },

    /**
     *
     */
    getTimeline() {
      this.$http
        .fetchPost(this.$api.StudentManageController, {
          type: 6,
          student_id: this.actUserInfo.user_id,
          school_id: this.userInfo.schoolId,
          uid: this.userInfo.id,
          token: this.userInfo.token,
        })
        .then((res) => {
          console.log(res);
          if (res.data.state == 200) {
            this.activities = res.data.data.data_list;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {});
    },
  },
  computed: {
    stuState() {
      return function (state) {
        switch (state) {
          case -1:
            return "低意向生";
          case -3:
            return "结业已流失";
          case 0:
            return "游客";
          case 1:
            return "咨询生";
          case 2:
            return "体验生";
          case 3:
            return "待分班";
          case 4:
            return "在读";
          case 5:
            return "结业";

          default:
            return "-";
        }
      };
    },
  },
  components: { TimeLine },
};
</script>
<style lang="scss">
.stu_info {
  height: calc(100vh - 77px - 56px);
  display: flex;
  flex-direction: column;

  //表格内容部分
  .dv_table_view_content {
    min-width: 1100px;
    margin: 20px 0;

    ::v-deep .el-table .cell {
      padding-left: 0;
      padding-right: 0;
    }

    .com_tab_item {
      display: flex;
      flex-direction: column;
      height: 54px;
      align-items: center;
      justify-content: center;
    }

    //底部的分页器部分
    .bom_page {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      margin-top: 18px;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
    }
  }

  .el-timeline {
    height: 300px;
    overflow-y: scroll;
    margin: 20px 0 0 80px;
    min-width: 200px;
    padding-right: 20px;
    .el-timeline-item {
      .el-timeline-item__wrapper {
        .el-timeline-item__content {
          .el-card {
            .el-card__body {
              padding: 10px;
              h4 {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
